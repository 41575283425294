import React from "react";

function ApplicationsThoseState({ single }) {
  const electricModalText = single?.condition_electric
    ? single?.condition_electric.filter((item) => item !== "null").join(",")
    : [];

  const enfineModalText = single?.condition_engine
    ? single?.condition_engine.filter((item) => item !== "null").join(",")
    : [];

  const gearModalText = single?.condition_gear
    ? single?.condition_gear.filter((item) => item !== "null").join(",")
    : [];

  const transmissionModalText = single?.condition_transmission
    ? single?.condition_transmission.filter((item) => item !== "null").join(",")
    : [];

  return (
    <>
      <div className="info-item">
        <span>Электроника</span>
        {single?.condition_electric &&
          single?.condition_electric.length !== 0 && (
            <> {"Неисправности электрики: " + electricModalText}</>
          )}
      </div>
      <div className="info-item">
        <span>Трансмиссия</span>
        {single?.condition_engine && single?.condition_engine.length !== 0 && (
          <> {"Неисправности КПП: " + enfineModalText}</>
        )}
      </div>
      <div className="info-item">
        <span>Двигатель</span>
        {single?.condition_gear && single?.condition_gear.length !== 0 && (
          <> {"Неисправности двигателя: " + gearModalText}</>
        )}
      </div>
      <div className="info-item">
        <span>Ходовая</span>
        {single?.condition_transmission &&
          single?.condition_transmission.length !== 0 && (
            <> {"Неисправности ходовой: " + transmissionModalText}</>
          )}
      </div>
    </>
  );
}

export default ApplicationsThoseState;
