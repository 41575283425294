import React from 'react';

function ApplicationFooterChat(
    {
        messageContent,
        setMessageContent,
        sendMessage,
        handleKeyPress
    }) {
    return (

            <div className="input-group mb-3 mt-3 pr-2 pl-2">
                <input
                    onChange={(e)=>setMessageContent(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Сообщение"
                    value={messageContent}
                    onKeyPress={handleKeyPress}
                />
                <div className="input-group-append">
                        <button className="btn btn-success" type="button" onClick={sendMessage}>Отправить</button>
                </div>
            </div>
    );
}

export default ApplicationFooterChat;