import React, {useState} from 'react';
import MyModal from "../../../../modal/modal";

function ApplicationItemServicesModal({isServiceModalOpen, closeServicesModal,servicesToSelect,handleAddServiceToOrder}) {

    const addService = (parking_has_service_id,cost ) => {
       const serviceData = {
            parking_has_service_id: parking_has_service_id,
            cost: cost
        }
        handleAddServiceToOrder(serviceData)
    }
    return (
        <MyModal isOpen={isServiceModalOpen} onClose={closeServicesModal}  ariaHideApp={false}>
            <div className={'scrolling__type'}>
                <table
                    style={{overflowY: 'auto',
                        maxHeight: '550px'}}
                    className="price-list-table table-services"
                >
                    <thead style={{background:'#536e9b'}}>
                    <tr>
                        <th className="price-list-head" style={{color:'#fff'}}>Название</th>
                        <th style={{color:'#fff'}}> Цена</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {servicesToSelect?.map((service, index)=>{
                        return(
                            <>
                                <tr key={index}>
                                    <td>{service.parking_service.name}</td>
                                    <td>{service.cost}</td>
                                    <td>
                                        <a href="#"
                                           style={{minWidth:'150px'}}
                                           className="btn btn-warning"
                                           onClick={()=>{addService(service?.id,service?.cost)}}
                                        >
                                            Добавить услугу
                                        </a>
                                    </td>
                                </tr>

                            </>

                        )
                    })}

                    </tbody>
                </table>
            </div>
        </MyModal>
    );
}

export default ApplicationItemServicesModal;