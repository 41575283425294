import React from "react";

function ReportsByPatnerItem({ item, index }) {
  return (
    <>
      <tr className="car-show-modal">
        <td className="tr-id">{index + 1}</td>
        <td>{item.id}</td>
        <td>{item.partner?.shortname}</td>
        <td>{item.parking?.title}</td>
        <td>{item.external_id}</td>
        <td>{item?.car_mark_name}</td>
        <td>{item?.car_model_name}</td>
        <td>{item?.year}</td>
        <td>{item?.car_type_name}</td>
        <td>{item.vin}</td>
        <td>{item.license_plate}</td>
        <td>{item.status_name}</td>
        <td>{item.formated_arrived_at}</td>
        <td>{item.formated_issued_at}</td>
        <td>{item.parked_days}</td>
        <td>{item.parked_price}</td>

        {item?.parked_days_in_period && <td>{item?.parked_days_in_period}</td>}

        {item?.parked_price_in_period && (
          <td>{item?.parked_price_in_period}</td>
        )}
      </tr>
    </>
  );
}

export default ReportsByPatnerItem;
