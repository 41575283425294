import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PartnerSearchPage from "../search/search";
import { createPartner } from "../../../store/partners/partners-create-slice/partnersCreateSlice";
import Select from "react-select";
import selectStyle from "../../../utils/select-styles";
import { basePartner, selectArray } from "../../../utils/select-array";
import ModerationButton from "../../../components/partners/partner-moderation-button/partner-moderation-button";

function PartnerCreatePage() {
  useEffect(() => {
    dispatch(createPartner());
  }, []);
  const { createNewPartner } = useSelector((state) => state.partnersCreate);

  // Собираем данные для select
  const partnerType = selectArray(
    createNewPartner.partner_types,
    "partner_type"
  );

  //

  // Собираем данные с для создания
  const [partnerCreate, serPartnerCreate] = useState([]);
  //

  // Функция для отправки формы
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target ? e.target : e;
    let newValue = value;
    if (type === "checkbox") {
      newValue = checked ? 1 : 0;
    }
    serPartnerCreate({ ...partnerCreate, [name]: newValue });
  };

  //
  return (
    <PartnerSearchPage data={partnerCreate} title={createNewPartner.title}>
      {/* Создание нового заказчика */}
      <div className="inner-page__item">
        <div className="inner-item-title">О Заказчике</div>
        <div className="row">
          {/* Полное название */}
          <div className="col-6">
            <label className="field-style">
              <span>Полное название</span>
              <input
                type="text"
                className="form-control"
                name="name"
                required
                placeholder="Не указан"
                onChange={handleChange}
              />
            </label>
          </div>
          {/*  */}
          {/* Активен */}
          <div className="col-6">
            <label className="switch-radio-wrap mt-11px">
              <input type="checkbox" name="status" onChange={handleChange} />
              <span className="switcher-radio"></span>
              <span>Активен</span>
            </label>
          </div>
          {/*  */}
          {/* Короткое название */}
          <div className="col-6 mt-3">
            <label className="field-style">
              <span>Короткое название</span>

              <input
                type="text"
                className="form-control "
                name="shortname"
                required
                onChange={handleChange}
                placeholder="Не указан"
              />
            </label>
          </div>
          {/*  */}
          {/* ИНН */}
          <div className="col-6 mt-3">
            <label className="field-style">
              <span>ИНН</span>
              <input
                type="text"
                name="inn"
                onChange={handleChange}
                placeholder="Не указан"
              />
            </label>
          </div>
          {/*  */}
          {/* КПП */}
          <div className="col-6 mt-3">
            <label className="field-style">
              <span>КПП</span>
              <input
                type="text"
                name="kpp"
                required
                onChange={handleChange}
                placeholder="Не указан"
              />
            </label>
          </div>
          {/*  */}
          {/* Тип заказчика */}
          <div className="col-6 mt-3">
            <label className="field-style select-react__style">
              <span>Тип Заказчика</span>
              <Select
                options={partnerType}
                styles={selectStyle}
                name="partner_type"
                onChange={handleChange}
              />
            </label>
          </div>
          {/*  */}

          {/* База */}
          <div className="col-6 mt-3">
            <label className="field-style select-react__style">
              <span>База</span>
              <Select
                options={basePartner}
                styles={selectStyle}
                name="base"
                onChange={handleChange}
              />
            </label>
          </div>
          {/*  */}
          {/* Модерация */}
          <ModerationButton handleChange={handleChange} />
          {/*  */}
        </div>
      </div>
    </PartnerSearchPage>
  );
}

export default PartnerCreatePage;
