import axios from "axios";

const API_URL = "/v1/";

// Отчет по заказчикам

const reportByPartner = async (data) => {
  // log
  const response = await axios.get(API_URL + "report-by-partner", {
    params: data,
  });
  return response.data;
};

// Ссылка на отчет по заказчикам
const reportByPartnerLink = async (data) => {
  const response = await axios.get(API_URL + "csv-by-partner", {
    params: data,
  });
  return response.data;
};

//

// Отчет по всем заказчикам

const reportPartnerAll = async (data) => {
  const response = await axios.get(API_URL + "report-all-partner", {
    params: data,
  });
  return response.data;
};
//

// Ссылка по всем заказчикам
const reportByPartnerAllLink = async (data) => {
  const response = await axios.get(API_URL + "csv-all-partner", {
    params: data,
  });
  return response.data;
};

//

// Данные по отчету сотрудника

const reportEmployee = async (data) => {
  const response = await axios.get(API_URL + "report-by-employee", {
    params: data,
  });
  return response.data;
};
//

// Ссылка по отчету сотрудника
const reportByEmployeeLink = async (data) => {
  const response = await axios.get(API_URL + "csv-by-employee", {
    params: data,
  });
  return response.data;
};

//

// отчет по заказам
const reportByOrder = async ({data}) => {

  const response = await axios.get(API_URL + `service-order-report?parking_id=${data.parking_id}&partner_id=${data.partner_id}&completed=${data.completed}`);
  return response.data;
};

// отчет по заказам excel
const reportByOrderExcel = async (data) => {
  if (data === undefined){
    const data = {
      parking_id: 0,
      partner_id: 0,
      completed: 0
    }
    const response = await axios.get(API_URL + `service-order-report-excel?parking_id=${data.parking_id}&partner_id=${data.partner_id}&completed=${data.completed}`);
    return response.data;
  }else{
    const response = await axios.get(API_URL + `service-order-report-excel?parking_id=${data.parking_id}&partner_id=${data.partner_id}&completed=${data.completed}`);
    return response.data;
  }

};

const reportsService = {
  reportByPartner,
  reportPartnerAll,
  reportEmployee,
  reportByPartnerLink,
  reportByEmployeeLink,
  reportByPartnerAllLink,
  reportByOrder,
  reportByOrderExcel
};

export default reportsService;
