import axios from "axios";

let API_URL = "/v1/applications/";

const applicationOrderServiceByCustomer = async ({currentApplicationId, data}) => {
    const response = await axios.post(API_URL + `${currentApplicationId}/orders`, data );
    return response.status;
};

const createApplicationOrderServiceById = async ({currentApplicationId}) => {
    const response = await axios.get(API_URL + `${currentApplicationId}/orders` );
    return response.data;
};
const getApplicationOrderServiceById = async ({currentApplicationId, orderID}) => {
    const response = await axios.get(API_URL + `${currentApplicationId}/orders/${orderID}` );
    return response.data;
};

const cancelApplicationOrderServiceById = async ({currentApplicationId, currentApplicationOrderId}) => {
    const response = await axios.get(API_URL + `${currentApplicationId}/orders/${currentApplicationOrderId}/cancel` );
    return response.status;
};

const applicationOrderServiceAddToOrder = async ({currentApplicationId,currentApplicationOrderId,data}) => {
    const response = await axios.post(API_URL + `${currentApplicationId}/orders/${currentApplicationOrderId}/add-service`, data );
    return response.status;
};

const applicationOrderServiceDeleteFromOrder = async ({currentApplicationId,currentApplicationOrderId,data}) => {
    const response = await axios.delete(API_URL + `${currentApplicationId}/orders/${currentApplicationOrderId}/delete-service/${data}` );
    return response.status;
};
const applicationServiceOrderService = {
    applicationOrderServiceByCustomer,
    createApplicationOrderServiceById,
    getApplicationOrderServiceById,
    cancelApplicationOrderServiceById,
    applicationOrderServiceAddToOrder,
    applicationOrderServiceDeleteFromOrder
}

export default applicationServiceOrderService

