import React from "react";

function ApplicationAboutCar({ single }) {

  return (
    <div className="row no-gutters">
      <div className="col-6">
        <div className="info-item">
          <span>ПТС</span>
          <span>
            {single.application?.pts} {single.application?.pts_type}
            <br /> {single.application.pts_provided && "(Принят на хранение)"}
          </span>
        </div>
        <div className="info-item">
          <span>СТС</span>
          {single.application?.sts}
          <br /> {single.application.sts_provided && "(Принят на хранение)"}
        </div>
        <div className="info-item">
          <span>Пробег</span>
          {single.application?.milage} км
        </div>
      </div>
      <div className="col-6">
        <div className="info-item">
          <span>Кол-во владельцев</span>
          {single.application?.owner_number}
        </div>
        <div className="info-item">
          <span>Кол-во ключей</span>
          {single.application?.car_key_quantity
            ? single.application?.car_key_quantity
            : 0}
        </div>
      </div>
    </div>
  );
}

export default ApplicationAboutCar;
