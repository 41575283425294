import React, {useState} from 'react';
import Select from "react-select";
import selectStyle from "../../../../utils/select-styles";
import {selectArray, selectArrayParking} from "../../../../utils/select-array";
import {reportsByPartnerLink} from "../../../../store/reports/reportsByPartnerSlice/reportsByPartnerSlice";
import {useDispatch} from "react-redux";

function ReportHeader(
    {
        reportsByPartner,
        link,
        showReportByOrder,
        total,
        linkToExcel,
        getFilteredExcel
    }) {
    const dispatch = useDispatch();
    const partnersSelect = selectArray(reportsByPartner.partners, "partner_id[]");
    const parkingSelect = selectArrayParking(
        reportsByPartner.parking,
        "parking_id[]"
    );
    const [selectedPartner, setSelectedPartner] = useState(0);
    const [selectedParking, setSelectedParking] = useState(0);
    const [completed, setCompleted] = useState(0);

    const handleSelectChange = (selected, setSelectedOptions) => {
        setSelectedOptions(selected.value);
        const data = {
            partner_id: selectedPartner,
            parking_id: selectedParking,
            completed: completed,
        }
        getFilteredExcel(data)
    };
    const handleCompletedChange =(e)=>{

        setCompleted(e.target.value)
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
            const data = {
                partner_id: selectedPartner,
                parking_id: selectedParking,
                completed: completed,
            }
            showReportByOrder(data)
            getFilteredExcel(data)
    }
    const hadleClick = (e) => {
        e.preventDefault();
        window.location.replace(linkToExcel);
    };
    return (
        <div className="container page-head-wrap">
            <div className="page-head">
                <div className="page-head__top d-flex align-items-center">
                    <h1>Отчёт по Заказам</h1>
                </div>
            </div>
            <form onSubmit={handleSubmit} className="filter">
                <div className="d-flex align-items-center mb-3">
                    {/* заказчик */}
                    <label className="field-style  select-react__style">
                        <span>заказчик</span>
                        <Select
                            options={partnersSelect}
                            styles={selectStyle}
                            onChange={(selected) =>
                                handleSelectChange(selected, setSelectedPartner)
                            }
                            id="status-selection"
                            name="partner_id[]"
                        />
                    </label>
                    {/*  */}

                    {/* Стоянка */}
                    <label className="field-style select-react__style">
                        <span>Стоянка</span>
                        <Select
                            // isMulti
                            options={parkingSelect}
                            styles={selectStyle}
                            onChange={(selected) =>
                                handleSelectChange(selected, setSelectedParking)
                            }
                            name="parking_id[]"
                        />
                    </label>
                    {/*  */}

                    {/* Выполненные  */}
                    <label className="field-style select-react__style">
                        <span>Выполненные</span>
                        <select
                            onChange={handleCompletedChange}
                        >
                            <option key={0} value="0">Не выполненые</option>
                            <option key={1} value="1">Выполненые</option>
                        </select>
                    </label>
                    {/*    */}
                </div>
                <button type="submit" className="btn btn-primary ml-auto">
                    Показать
                </button>
                <div className="btn btn-dowenload" onClick={hadleClick}></div>
                <label className="field-style pl-5">
                    Всего:
                    {total}
                </label>
            </form>
        </div>
    );
}

export default ReportHeader;