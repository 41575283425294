import axios from "axios";

// partners All LiST
let API_URL = "/v1/partners";

// Список partners/
const partners = async (paginate, query, search) => {
  let queryString = "";
  if (query) {
    queryString = Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join("&");
  }
  const response = await axios.get(`${API_URL}?${queryString}`, {
    params: {
      page: paginate,
      search: search,
      params: query,
    },
  });
  return { data: response.data, status: response.status };
};
//

// Редактирование данных partner
const partnerEditData = async (id) => {
  const response = await axios.get(API_URL + "/" + id + "/edit");
  return response.data;
};

const partnerEditPut = async (id, data) => {
  const response = await axios.put(API_URL + "/" + id, data);
  return { data: response.data, status: response.status };
};
//

// Удаление partner
const partnerDelete = async (id) => {
  const response = await axios.delete(API_URL + "/" + id);
  if (response) {
    return id;
  } else {
    return { data: response.data, status: response.status };
  }
};
//

// Создание partner
const partnersCreate = async () => {
  const response = await axios.get(API_URL + "/create");
  return response.data;
};

const partnersCreateData = async (data) => {
  const response = await axios.post(API_URL, data);
  return { data: response.data, status: response.status };
};
//

// Поиск заказчика
const partnersSearch = async (data) => {
  const response = await axios.post("/v1/partner/search-vin", data);
  return { data: response.data, status: response.status };
};
//

// Создание заказчикам пользователей
const partnerUserCreate = async (id, data) => {
  const response = await axios.post(`/v1/partner-users/partner/${id}`, data);
  return { data: response.data, status: response.status };
};
//

// Редактирвоние заказчикам пользователей
const partnerUseEditData = async (id, user_id) => {
  const response = await axios.get(
    `/v1/partner-users/${user_id}/partner/${id}/edit`
  );
  return response.data;
};

const partnerUserEditPut = async (id, user_id, data) => {
  const response = await axios.put(
    `/v1/partner-users/${user_id}/partner/${id}`,
    data
  );
  return response.status;
};
//

// Добавить в свой список заказчиков из общего списка
const partnerAdd = async (id, user_id) => {
  const response = await axios.get(`/v1/partner/add-user/${id}/${user_id}`);
  return { data: response.data, status: response.status };
};

//

// Добавить в свой список заказчиков из общего списка
const partnerRemove = async (id, user_id) => {
  const response = await axios.get(`/v1/partner/remove-user/${id}/${user_id}`);
  return response.status;
};

//

// Данные текущего партнера для модалки
const partnerGetInfo = async (id) => {
  const response = await axios.get(`/v1/partner/get-modal-users-content/${id}`);
  return response.data;
};

//

// Тоглим активацию партнера
const partnerToggleActiveStatus = async (partnerId, userId) => {
  // console.log(partnerId)
  const response = await axios.post(`/v1/partner/user/${userId}`, {
    partnerId: partnerId,
  });
  return { data: response.data, status: response.status };
};

//

// Подтверждение офферты
const partnerOfferAccepted = async (userId) => {
  const response = await axios.get(`/v1/accepted/${userId}`);
  return { data: response.data, status: response.status };
};

//
const partnersService = {
  partners,
  partnerEditData,
  partnerEditPut,
  partnerDelete,
  partnersCreate,
  partnersCreateData,
  partnersSearch,
  partnerUserCreate,
  partnerUseEditData,
  partnerUserEditPut,
  partnerAdd,
  partnerRemove,
  partnerGetInfo,
  partnerToggleActiveStatus,
  partnerOfferAccepted,
};

export default partnersService;
