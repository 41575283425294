import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {partnersData} from "../../../store/partners/partnersSlise";
import PartnersItem from "../../../components/partners/partners-item";
import ReactPaginate from "react-paginate";
import PartnerUserModal from "../modal/modal";
import {partnersDelete} from "../../../store/partners/partner-delete/partnerDeleteSlice";
import swal from "sweetalert";

function PartnersIndex() {
  const { partners, title } = useSelector((state) => state.partners);
  const [pageCount, setPageCount] = useState(1);
  const [queryParametrPartner, setQueryParametrParnter] = useState({});
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState({});
  const handlePageChange = (selectedObject) => {
    setQueryParametrParnter({
      ...queryParametrPartner,
      paginate: selectedObject.selected + 1,
    });

    dispatch(partnersData(queryParametrPartner));
  };
  useEffect(() => {
    setQueryParametrParnter({ ...queryParametrPartner, paginate: pageCount });
    dispatch(partnersData(queryParametrPartner));
  }, []);

  // Пагинация
  useEffect(() => {
    setPageCount(partners?.last_page);
  }, [partners]);
  //
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Фильтрация по общим и пользовательским
  const handleFilterChange = (filterType) => {
    const newFilter = { [filterType]: "on" };
    setQueryParametrParnter({ ...queryParametrPartner, query: newFilter });
  };

  useEffect(() => {
    dispatch(partnersData(queryParametrPartner));
  }, [queryParametrPartner]);

  //

  // Фильтрация по общим и пользовательским
  const handleSearch = (e) => {
    const { name, value } = e.target;
    setQueryParametrParnter({ ...queryParametrPartner, [name]: value });
  };
  //

  //delete partner
  const handleDeletePartner = (id) => {
    swal({
      title: "Вы уверены что хотите удалить заказчика?",
      icon: "warning",
      buttons: ["Отменить", "Да"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(partnersDelete(id)).then((resp) => {
          if (resp.meta.requestStatus === "fulfilled"){
            dispatch(partnersData(queryParametrPartner));
            swal("Заказчик удален успешно", {
              icon: "success",
            });
          }else{
            swal(resp.payload.message, {
              icon: "error",
            });
          }
        })
      }else {
        swal("Ошибка при удалении заказчика", {
          icon: "error",
        });
      }
    })
  }
  //

  // console.log(partners)

  return (
    <>
      <div className="container page-head-wrap">
        <div className="page-head">
          <div className="page-head__top d-flex align-items-center">
            <h1>{title}</h1>
            <label className="field-style blue">
              <span>Поиск</span>
              <input
                type="text"
                placeholder="Поиск по столбцам"
                name="search"
                onChange={handleSearch}
              />
            </label>

            <label className="switch-radio-wrap ">
              <input
                type="radio"
                name="search"
                value="public"
                onChange={() => handleFilterChange("public")}
              />
              <span className="switcher-radio onfilter"></span>
              <div>Общие</div>
            </label>

            <label className="switch-radio-wrap ml-5">
              <input
                type="radio"
                name="search"
                value="user"
                onChange={() => handleFilterChange("user")}
              />
              <span className="switcher-radio onfilter"></span>
              <div>Пользовательские</div>
            </label>

            <div className="ml-auto d-flex">
              <Link to="/partners/search" className="btn btn-white">
                Добавить
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="inner-page">
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th className="sortable" scope="col">
                  Название
                </th>
                <th className="sortable" scope="col">
                  Тип
                </th>
                <th className="sortable" scope="col">
                  ИНН
                </th>
                {/*<th className="sortable" scope="col">*/}
                {/*  КПП*/}
                {/*</th>*/}
                <th className="sortable" scope="col">
                  Пользователь
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className={'partner-users-table'}>
              {partners.length !== 0
                ? partners.data.map((item, index) => (
                    <PartnersItem
                      item={item}
                      key={index}
                      index={index}
                      setIsModalOpen={setIsModalOpen}
                      setCurrentUser={setCurrentUser}
                      handleDeletePartner={handleDeletePartner}
                    />
                  ))
                : ""}
            </tbody>
          </table>
          <div className="container">
            <ReactPaginate
              pageCount={pageCount}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              previousLinkClassName={"page-link"}
              breakClassName={"page-item"}
              nextLinkClassName={"page-link"}
              nextLabel={`>`}
              previousLabel={`<`}
              pageClassName={"page-item"}
              disabledClassName={"disabled"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      {/* Модальное окно */}
      <PartnerUserModal
        currentUser={currentUser}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
      {/*  */}
    </>
  );
}

export default PartnersIndex;
