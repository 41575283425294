import React from 'react';
import avatar from "../../../../../../../images/avatar.png";
import {useSelector} from "react-redux";

function ApplicationChatItem({message,orentation}) {
    const { user } = useSelector((state) => state.auth);
    const toSide = message?.role === user?.role ? 'user-mess' : ''
    const you = message?.role === user?.role ? '(Вы)' : ''
    return ( message?.content &&
        <div className={`chat__item ${toSide} ${message?.role === user?.role ? 'your-message' : ''}`}>
            <div className="d-flex ">
                <div className="chat__user-img">
                    <img src={avatar} alt="" />
                </div>
                <div className="chat__user-info">
                    <div className="chat__user-name ">{message?.name } <span className={'message-owner-role'}>{you}</span>
                        {message?.role !== user?.role ?
                            <span className={'message-owner-role'}> ( { message?.role } )</span> : ''
                        }
                    </div>
                    <div className="chat__date">{message?.created_at}</div>
                </div>
            </div>
            <div className="chat__mess">
                {message?.content}
            </div>
        </div>
    );
}

export default ApplicationChatItem;