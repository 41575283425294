import axios from "axios";
const API_URL = '/v1/notifications'

const headerNotification = async () => {
    const response =  await axios.get(API_URL);
    return response.data;
};
const headerNotificationRead = async (id) => {
    const response =  await axios.post('/v1/read-notification', id);
    return response.status;
}
const headerNotificationService = {
    headerNotification,
    headerNotificationRead
}

export default headerNotificationService