import React, {useCallback, useEffect, useState} from "react";
import HeaderNotificationMessages from "./header-notification/header-notification-messages";
import HeaderProfile from "./header-profile/header-profile";
import ApplicationItemModal
    from "../../applications/applications-index/applications-list/applications-item-modal/applications-item-modal";
import {useDispatch, useSelector} from "react-redux";
import {applicationsSingle} from "../../../store/applications/applications-single/applicationsSingleSlice";
import {getMessagesFromChat} from "../../../store/chat/chatSlice";
import {applicationServicesGetById} from "../../../store/applications/application-service-order/applicationServiceOrderSlice";
import {notificationData} from "../../../store/notofocations/headerNotificationSlice";
import {onMessage} from "firebase/messaging";
import {db, messaging} from "../../../firebase";
import HeaderNotification from "./header-notification/header-notification";
import {onValue, ref, limitToLast} from "firebase/database";
import newLogo from "../../../images/Logo.svg"

const RightMenu = () => {

    const dispatch = useDispatch()
    const [notifications, setNotifications] = useState([]);
    const [newNotification, setNewNotification] = useState(0);
    const [newNotificationMessage, setNewNotificationMessage] = useState(0);
    const [notificationFromFireBase, setNotificationFromFireBase] = useState({});
    const {user} = useSelector((state) => state.auth);


    //modal
    const {single} = useSelector((state) => state.applicationSingle);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
    const [favorite, setFavorite] = useState(0);
    const [error, setError] = useState(null);
    //set active tab
    const [activeTabKey, setActiveTabKey] = useState({
        tabName: 'tab1',
        serviceId: 0,
        applicationId: 0
    })

    const openServicesModal = () => {
        setIsServiceModalOpen(true)
    }
    const openModalFromNotification = (id, type) => {
        setIsModalOpen(true);
        // dispatch(applicationsSingle(id))
        // let currentApplicationId = id;
        // if (type === 'chat') {
        // } else if (type === 'order') {
        //     dispatch(applicationServicesGetById({currentApplicationId})).then((response) => {
        //         console.log(response)
        //     });
        // }
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const closeServicesModal = () => {
        setIsServiceModalOpen(false)
    }
    //myparkingdev  (dev)
    //myparkingprod  (prod)
    useEffect(() => {
        const notificationsRef = ref(db, `myparkingdev/notifications/users/${user.id}`, limitToLast(1));
        const unsubscribe = onValue(notificationsRef, (snapshot) => {
            setNotificationFromFireBase(snapshot.val());
            setError(null);
        }, (error) => {
            setError(error.message);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        dispatch(notificationData()).then((resp) => {
            // setNotifications(resp.payload?.notifications?.slice(0, 10))
            setNotifications(resp.payload?.notifications)
        })
    }, [dispatch])

    useEffect(() => {
        // if (notificationFromFireBase?.type === "application"){
        //     setNewNotification(newNotification + 1)
        // }
        // if (notificationFromFireBase?.type === "applicationChat"){
        //     setNewNotificationMessage(newNotificationMessage + 1)
        // }
        // setNotifications([notificationFromFireBase, ...notifications])
    }, [notificationFromFireBase])

    // useEffect(()=>{
    //     localStorage.setItem('notifications', JSON.stringify(notifications))
    // },[notifications])

    return (
        <>
            <div className="header__user ms-auto d-flex align-items-center">
                <HeaderNotificationMessages
                    notifications={notifications}
                    newNotification={newNotificationMessage}
                    setNewNotification={setNewNotificationMessage}
                    openModal={openModalFromNotification}
                    setActiveTabKey={setActiveTabKey}
                />
                <HeaderNotification
                    notifications={notifications}
                    newNotification={newNotification}
                    setNewNotification={setNewNotification}
                    openModal={openModalFromNotification}
                    setActiveTabKey={setActiveTabKey}
                />
                <HeaderProfile/>
            </div>
            <ApplicationItemModal
                single={single}
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                setFavorite={setFavorite}
                openServicesModal={openServicesModal}
                isServiceModalOpen={isServiceModalOpen}
                closeServicesModal={closeServicesModal}
                tab={activeTabKey}
            />
        </>


    );
};

export default RightMenu;
