import React from "react";
import ImageUploaderEdit from "../../../../../image-upload/image-edit-upload";

function ApplicationsPhoto({ single }) {

  return (
    <>
      <ImageUploaderEdit
        id={single?.application?.id}
        oldimage={single?.application?.images}
      />
    </>
  );
}

export default ApplicationsPhoto;
