import React, {useEffect, useState} from 'react';

function ApplicationServiceList({services, openCurrentOrders, formatDate, getStatusColor}) {
    const [filteredServices, setFilteredServices] = useState([])
    const [activeTab, setActiveTab] = useState('all')
    const servicesFilter = (filterBy) => {
        switch (filterBy){
            case 'all':
                setActiveTab(filterBy)
                setFilteredServices(services)
                break;
            case 'toPartner':
                setActiveTab(filterBy)
                setFilteredServices(services.filter(item => item.status_code === 'toPartner'))
                break;
            case 'completed':
                setActiveTab(filterBy)
                setFilteredServices(services.filter(item => item.status_code === 'completed' ))
                break;
            case 'in-progress':
                setActiveTab(filterBy)
                setFilteredServices(services.filter(item => item.status_code === 'in-progress'))
                break;
            case 'cancelled':
                setActiveTab(filterBy)
                setFilteredServices(services.filter(item => item.status_code === 'cancelled'))
                break;
            default:
                setFilteredServices(services)
        }
    }
    useEffect(() => {
        setFilteredServices(services)
    }, [services])
    return (
        <div className={'order__list row d-flex'}>
            <h5 className={'text-center mb-3'}>Мои заказы</h5>
            <div className={'order__list-filters row mb-4 cursor-pointer'} >
                <div
                    className={`custom-w-20 ${activeTab === 'all' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('all')}}
                >
                    <span >Все</span>
                </div>
                <div
                    className={`custom-w-20 ${activeTab === 'toPartner' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('toPartner')}}
                >
                    <span>На согласов</span>
                </div>
                <div
                    className={`custom-w-20 ${activeTab === 'in-progress' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('in-progress')}}
                >
                    <span>В работе</span>
                </div>
                <div
                    className={`custom-w-20 ${activeTab === 'completed' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('completed')}}
                >
                    <span>Готово </span>
                </div>
                <div
                    className={`custom-w-20 ${activeTab === 'cancelled' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('cancelled')}}
                >
                    <span>Отменено</span>
                </div>
            </div>
            {filteredServices.length > 0 ? filteredServices.map((item, index) => {
                return (
                    <a href="#" key={index} className={'mb-0 col-md-6'} onClick={(e) => {
                    openCurrentOrders(item.id, item.application_id)
                    }}>
                        <span
                            style={{fontSize: '14px'}}
                        >
                            {`Заказ № ${item.id}`}
                        </span>
                        <span
                            style={{fontSize: '12px', color: 'gray'}}
                        >
                            {` от ${formatDate(item.created_at)}`}
                        </span>
                        <br/>
                        <span
                            style={{
                            fontSize: '11px',
                            color: getStatusColor(item.status_code),
                            textAlign: 'left',
                            display: "block"
                            }}
                        >
                            {item.status_name}
                        </span>
                    </a>
                )
                }) :
                    <div className={'col-md-12 opacity-25'}>
                        <b>к сожалению тут пусто ...</b>
                    </div>
            }
        </div>

    )
}

export default ApplicationServiceList;