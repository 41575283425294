import React from "react";

function ParkingEditService(
    {
        parkingServicesEdit,
        handleServiceChange,
        parkingPartners,
        handleSelectChange,
        selectedOption,
        inputDisable
    }
)
{

    return (
        <div className="row no-gutters">
            <div className="col-md-12">
                <div className="inner-page__item">
                    <div className="inner-item-title">Прайс-лист</div>

                    <div className="d-flex align-items-center">
                        <label
                            className="field-style mt-3"
                            // style="width: 100%; max-width: 605px;"
                        >
                            <span>Шаблон прайс-листов</span>
                            <select
                                name=""
                                id=""
                                className="page-select"
                                onChange={handleSelectChange}
                                // style="width: 100%; max-width: 605px;"
                            >
                                <option value={0}>Базовые цены</option>
                                {parkingPartners && parkingPartners.map((item, index)=>{
                                    return (
                                        <option
                                            key={index}
                                            value={item.id}
                                            selected={selectedOption == parseInt(item.id)  ? 'selected' : ''}
                                        >
                                            {item.name}
                                        </option>
                                    )
                                })}

                            </select>
                        </label>
                    </div>
                </div>
            </div>

            <div className="d-flex w-100 mt-5">
                <table className="price-list-table price-table vertical-scrollable">
                    <thead>
                    <tr>
                        <th className="price-list-head">Парковка</th>
                        <th className="text-center">Активна</th>
                        <th>Цена для <br/> всех </th>
                        <th>Цена для <br/> нас </th>
                        <th>Контакты</th>
                        <th></th>
                        <th>Комментарий</th>
                    </tr>
                    </thead>
                    <tbody>
                    {parkingServicesEdit?.map((item, index) => {
                        return (
                            <>
                                <tr key={index}>
                                    <td><b>{item.category}</b></td>
                                </tr>
                                {item.services.map(service => (
                                    <tr>
                                        <td>{service.parking_service_name}</td>
                                        <td className="text-center">
                                            <label className="check-box-style">
                                                <input
                                                    type="checkbox"
                                                    name="is_active"
                                                    defaultChecked={service.is_active === 1 ? 'checked' : ''}
                                                    className={`chech-dd ${service.is_active}`}
                                                    onChange={(e) => {
                                                        handleServiceChange(e, service.parking_service_id,item.category)
                                                    }}

                                                />
                                                <span className={`${service.is_active === 1 ? 'checked' : ''}`}></span>
                                            </label>
                                        </td>
                                        <td
                                            className={'price-min-width'}
                                            >
                                            <div className="field-style">
                                                <input
                                                    className="m-0"
                                                    type="text"
                                                    name={'cost'}
                                                    value={service.cost}
                                                    onChange={(e) => {
                                                        handleServiceChange(e, service.parking_service_id,item.category)
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td  className={'price-min-width'}>
                                            <div className="field-style">
                                                <input
                                                    className={`cost_for_us m-0 ${inputDisable ? 'disabled' : ''}`}
                                                    type="text"
                                                    name={'cost_for_us'}
                                                    disabled={`${inputDisable ? 'disabled' : ''}`}
                                                    defaultValue={service.cost_for_us}
                                                    onChange={(e) => {
                                                        handleServiceChange(e, service.parking_service_id, item.category)
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td>

                                                <div className="field-style mr-0">
                                                    <input
                                                        type="text"
                                                        className="ml-0"
                                                        placeholder="Имя"
                                                        name={'contact_name'}
                                                        defaultValue={service.contact_name}
                                                        onChange={(e) => {
                                                            handleServiceChange(e, service.parking_service_id, item.category)
                                                        }}
                                                    />
                                                </div>


                                        </td>
                                        <td>
                                            <div className="field-style">
                                                <input
                                                    type="phone"
                                                    name={'contact_phone'}
                                                    placeholder="+7 (___) ___-__-__"
                                                    defaultValue={service.contact_phone}
                                                    onChange={(e) => {
                                                        handleServiceChange(e, service.parking_service_id, item.category)
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                    <textarea
                                        className="table-textarea"
                                        placeholder="Не указано"
                                        name="comment"
                                        id=""
                                        onChange={(e) => {
                                            handleServiceChange(e, service.parking_service_id, item.category)
                                        }}
                                    >
                                            {service.comment}
                                    </textarea>
                                        </td>
                                    </tr>
                                ))}
                            </>

                        )
                    })
                    }

                    </tbody>
                </table>
            </div>
            {/*<div className=" d-flex justify-content-end mt-3 mb-3 pr-4">*/}
            {/*    <button type="submit" name={'application'} className="btn btn-dark-blue">*/}
            {/*        Сохранить*/}
            {/*    </button>*/}
            {/*</div>*/}
        </div>
    );
}

export default ParkingEditService;
