import axios from "axios";

const API_URL = "/v1/applications";
const API_URL_PARKING = "/v1/parkings";
// Загрузка изображение при создание машины
const uploadApi = async (data) => {
  const response = await axios.post(API_URL + "/upload", data);
  return response.data;
};
//

// Удаление изображение
const imageDelete = async (id) => {
  const response = await axios.get("v1/application/remove/attachment/" + id);
  if (response) {
    return id;
  } else {
    return { data: response.data, status: response.status };
  }
};
//

// Получение временных изображение
const imageTemparary = async (token) => {
  const response = await axios.get("v1/application/show/attachment", {
    params: {
      token: token,
    },
  });
  return response.data;
};
//

// Загрузка изображение при обновлении машины
const uploadApiEdit = async (id, data) => {
  const response = await axios.post(API_URL + "/upload/" + id, data);
  return response.status;
};
//

//upload images to parking
const uploadApiForParking = async (data) => {
  const response = await axios.post(API_URL_PARKING + "/upload", data);
  return response.data;
};
//

// upload images by edit parking
const uploadApiEditParking = async (id, data) => {
  const response = await axios.post(API_URL_PARKING + "/upload/" + id, data);
  return response.status;
};
//


// get temporary images
const imageTemporaryParking = async (token) => {
  const response = await axios.get("/v1/parking/show/attachment", {
    params: {
      token: token,
    },
  });
  // console.log(response.data)
  return response.data;
};
//

// delete image parking
const imageDeleteParking = async (id) => {
  const response = await axios.get("/v1/parking/remove/attachment/" + id);
  if (response) {
    return id;
  } else {
    return { data: response.data, status: response.status };
  }
};
//
const uploadImageService = {
  uploadApi,
  imageDelete,
  imageTemparary,
  uploadApiEdit,
  uploadApiForParking,
  uploadApiEditParking,
  imageTemporaryParking,
  imageDeleteParking
};

export default uploadImageService;
