import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  createLegals,
  legalsCreatePost,
} from "../../../store/legal/legal-create-slice/legalCreateSlice";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

function LegalCreatePage() {
  const { createNewLegal } = useSelector((state) => state.legalCreate);

  const navigate = useNavigate();
  const [legalCreateForm, setLegalCreateForm] = useState({
    name: "",
    reg_number: "",
    inn: "",
    kpp: "",
    status: 0,
  });

  // Функция для отправки формы

  const handleChange = (e) => {
    if (e.target) {
      const { name, value, type, checked } = e.target;
      let newValue = value;
      if (type === "checkbox") {
        newValue = checked ? 1 : 0;
      }
      setLegalCreateForm({ ...legalCreateForm, [name]: newValue });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(legalsCreatePost(legalCreateForm)).then((response) => {
      if (response.payload === 201) {
        swal("Юридическое лицо успешно создана!", {
          icon: "success",
        }).then(() => {
          navigate("/legals");
        });
      } else {
        let errorMessage = "Ошибка при создании юридического лица!";

        if (response.payload && response.payload.errors) {
          const errorMessages = Object.values(response.payload.errors).reduce(
            (acc, val) => acc.concat(val),
            []
          );
          errorMessage = errorMessages.join("\n");
        }

        swal(errorMessage, {
          icon: "error",
        });
      }
    });
  };
  //

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createLegals());
  }, [dispatch]);

  return (
    <>
      {createNewLegal && (
        <form onSubmit={handleSubmit}>
          <div className="container page-head-wrap">
            <div className="page-head">
              <div className="page-head__top d-flex align-items-center">
                <h1>{createNewLegal.title}</h1>
                <div className="ml-auto d-flex">
                  <button type="submit" className="btn btn-white">
                    Создать
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="inner-page">
              <div className="row no-gutters">
                <div className="col-md-12">
                  <div className="inner-page__item">
                    <div className="inner-item-title">О юр. лице</div>
                    <div className="row">
                      <div className="col-6 mt-3">
                        <label className="field-style">
                          <span>Название</span>
                          <input
                            id="name"
                            type="text"
                            value={legalCreateForm.name}
                            name="name"
                            required
                            placeholder="Не указан"
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                      <div className="col-6">
                        <label className="switch-radio-wrap mt-11px">
                          <input
                            type="checkbox"
                            name="status"
                            value={legalCreateForm.status}
                            onChange={handleChange}
                          />
                          <span className="switcher-radio"></span>
                          <span>Активен</span>
                        </label>
                      </div>
                      <div className="col-6 mt-3">
                        <label className="field-style">
                          <span>ИНН</span>
                          <input
                            id="inn"
                            type="text"
                            value={legalCreateForm.inn}
                            name="inn"
                            required
                            placeholder="Не указан"
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                      <div className="col-6 mt-3">
                        <label className="field-style">
                          <span>КПП</span>
                          <input
                            id="kpp"
                            type="text"
                            value={legalCreateForm.kpp}
                            name="kpp"
                            required
                            placeholder="Не указан"
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                      <div className="col-6 mt-3">
                        <label className="field-style">
                          <span>ОГРН</span>
                          <input
                            id="reg_number"
                            type="text"
                            value={legalCreateForm.reg_number}
                            name="reg_number"
                            required
                            placeholder="Не указан"
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default LegalCreatePage;
