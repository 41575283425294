import React from 'react';
import ApplicationLogsItem from "./application-logs-item/application-logs-item";

function ApplicationLogs({logs}) {
    return (
        <table class="table">
            <thead>
                <tr>
                    {/*<th>ID</th>*/}
                    <th>Дата</th>
                    <th>Действие</th>
                </tr>
            </thead>
            <tbody>
            {logs.map((item)=>{
                return (
                    <ApplicationLogsItem
                        id={item.id}
                        key={item.id}
                        date={item.date}
                        action={item.action}
                    />
                )
            })}
            </tbody>
        </table>
    );
}

export default ApplicationLogs;