import React, {useEffect, useState} from 'react';
import {applicationsSingle} from "../../../../../store/applications/applications-single/applicationsSingleSlice";
import {useDispatch} from "react-redux";
import {currentApplicationServiceById} from "../../../../../store/applications/application-service-order/applicationServiceOrderSlice";
import {getMessagesFromChat} from "../../../../../store/chat/chatSlice";
import {notificationReade} from "../../../../../store/notofocations/headerNotificationSlice";

function HeaderNotificationItem(
    {
        item,
        openModal,
        setActiveTabKey,
        setNewNotification
    }) {


    const dispatch = useDispatch()
    const handleClick = (applicationId, type, orderID, e) => {
        e.preventDefault()
        const id = {
            notificationId:applicationId
        }
        dispatch(notificationReade(id)).then((resp)=>{
        })
        switch (type){
            case 'order' :
                setActiveTabKey(
                    {
                        tabName : 'tabCurrentServices',
                        serviceId : applicationId,
                        applicationId : orderID
                    })
                // const currentApplicationId = applicationId
                // dispatch(currentApplicationServiceById({currentApplicationId,orderID})).then((response)=>{
                // })

                openModal(applicationId, type='order')
                setNewNotification((prevNotifications) => {
                    if (prevNotifications !== 0) {
                        return prevNotifications - 1;
                    } else {
                        return 0;
                    }
                });
                break
        }
    }
     return (
        item && item.type === 'application' ?  <li
            key={item.id}
            className={`${item.type } ${item.read_at !== null ? 'read-at' : ''}`}
        >
            <a href={item.type === 'application' ? `/applications/${item.id}/edit` : '#'} className="all-notif application">
                <b>#{item.id}:</b> {item.short}
            </a>
        </li> :
            item && item.type === 'order' ? <li
            key={item.id}
            className={`${item.type } ${item.read_at !== null ? 'read-at' : ''}`}
            onClick={(e)=>{handleClick(item.id,item.type, item.order_id, e)}}>
            <a href={'#'} className="all-notif order">
                <b>#{item.id}:</b> {item.short}
            </a>
        </li> : ''

    )
}

export default HeaderNotificationItem;