import React from 'react';

function ApplicationFooterService(
    {
        item,
        role,
        sendApplicationSelectedServices,
        servicesForFooter,
        k,
        cancelServiceById,
        services,
        approveServiceByManager,
        approveServiceByPartner,
        openServicesModal,
        sendOrderToWork,
        sendOrderToWorkFromCreatingOrder
    }) {

    const formatDate = (inputDate) => {
        const dateObject = new Date(inputDate);
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObject.getFullYear().toString().slice(2, 4);

        return `${day}.${month}.${year}`;
    };
    switch (k){
        case 'tabServices' :
            return (
                <>
                    <div className="tab-content tab-content-bottom " style={{width:'100%'}}>
                        <div id="v-pills-tab10-bottom" className="tab-pane fade show in active">
                            <div className="modal-block__footer d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="total-service">Заказ на <span>{servicesForFooter.length}</span> услуг</div>
                                    <p className="text11pxnormalblue d-block total-service-name">
                                        {servicesForFooter.map((item,index)=>  item.name + " ")}
                                    </p>

                                </div>
                                {/*{role === 'Partner' || role === 'Manager' ?*/}
                                    <div className="d-flex">
                                        <a href="#" onClick={() => {
                                            sendApplicationSelectedServices()
                                        }}
                                           className={`btn btn-warning ${servicesForFooter.length === 0 ? 'disabled' : ''}`}

                                        >Сделать заказ</a>
                                        {role !== 'Partner' &&
                                        <a href="#"
                                           // style={{minWidth:'250px'}}
                                           onClick={()=>{sendOrderToWorkFromCreatingOrder()}}
                                           className={`btn btn-success ${servicesForFooter.length === 0 ? 'disabled' : ''}`}
                                        >
                                            В работу
                                        </a> }
                                    </div>
                                {/*: ''*/}
                                {/*}*/}
                            </div>

                        </div>
                    </div>
                </>
            )
        case 'tabCurrentServices' :
            if ( role !== 'Partner'){
                return (
                    <>
                        <div className="tab-content tab-content-bottom " style={{width:'100%'}}>
                            <div id="v-pills-tab10-bottom" className="tab-pane fade show in active">
                                <div className="modal-block__footer d-flex justify-content-between align-items-center">
                                    <div>
                                        <div className="total-service">Заказ № <span>{services.currentService.order?.id}</span>  от {formatDate(services.currentService.order?.created_at)} </div>
                                        <p className="text11pxnormalblue d-block total-service-name">
                                            {services.currentService.order?.status_name}
                                        </p>

                                    </div>
                                    <div className="d-flex gap-2">
                                        { services.currentService.order?.status_code === 'toManager' ?
                                            <div  style={
                                                {
                                                    display:'flex',
                                                    gap : '10px',
                                                    alignItems:'center',
                                                    justifyContent : 'center'
                                                }}>
                                            <span
                                                onClick={()=>{openServicesModal()}}
                                                style={
                                                    {
                                                        cursor:'pointer',
                                                        display:'flex',
                                                        gap : '10px'
                                                    }}
                                            >
                                                <svg
                                                    style={{marginTop:'3px' }}
                                                    width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                 <path d="M8 0.5C8.55228 0.5 9 0.947715 9 1.5V7.5H15C15.5523 7.5 16 7.94772 16 8.5C16 9.05229 15.5523 9.5 15 9.5H9V15.5C9 16.0523 8.55228 16.5 8 16.5C7.44772 16.5 7 16.0523 7 15.5V9.5H1C0.447715 9.5 0 9.05229 0 8.5C0 7.94772 0.447715 7.5 1 7.5H7V1.5C7 0.947715 7.44772 0.5 8 0.5Z" fill="#536E9B"/>
                                                </svg>
                                                Добавить услугу

                                            </span>
                                                <div className="service_buttons_col">
                                                    <a href="#"
                                                       style={{minWidth:'250px'}}
                                                       onClick={()=>{approveServiceByManager()}}
                                                       className="btn btn-warning"
                                                    >
                                                        Согласовать
                                                    </a>
                                                    {role !== 'Partner' &&
                                                        <a href="#"
                                                        style={{minWidth:'250px'}}
                                                        onClick={()=>{sendOrderToWork()}}
                                                        className={`btn btn-success`}
                                                        >
                                                        В работу
                                                        </a>
                                                    }
                                                </div>

                                            </div>: ''
                                        }
                                        {services.currentService.order?.status_code !== 'cancelled' ?
                                            <a href="#"
                                               style={{maxWidth: '80px'}}
                                               onClick={() => {
                                                   cancelServiceById()
                                               }}
                                               className="btn-text btn-outline-danger text-center">
                                                Отменить заказ
                                            </a> : ''
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                )
            }
            if(role === 'Partner'){
                return (
                    <>
                        <div className="tab-content tab-content-bottom " style={{width:'100%'}}>
                            <div id="v-pills-tab10-bottom" className="tab-pane fade show in active">
                                <div className="modal-block__footer d-flex justify-content-between align-items-center">
                                    <div>
                                        <div className="total-service">Заказ № <span>{services.currentService.order?.id}</span>  от {formatDate(services.currentService.order?.created_at)} </div>
                                        <p className="text11pxnormalblue d-block total-service-name">
                                            {services.currentService.order?.status_name}
                                        </p>

                                    </div>
                                    <div className="d-flex gap-2">
                                        {services.currentService.order?.status_code === 'toPartner' ?
                                                <a href="#"
                                                   onClick={()=>{approveServiceByPartner()}}
                                                   className="btn btn-success"
                                                >
                                                    Согласовать
                                                </a> : ''
                                        }
                                        {services.currentService.order?.status_code !== 'cancelled' ?
                                            <a href="#"
                                               style={{maxWidth: '80px'}}
                                               onClick={() => {
                                                   cancelServiceById()
                                               }}
                                               className="btn-text btn-outline-danger text-center">
                                                Отменить заказ
                                            </a> : ''
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                )
            }

        default :
            return (
                <>
                    <div className="tab-content tab-content-bottom " style={{width:'100%'}}>
                        <div id="v-pills-tab10-bottom" className="tab-pane fade show in active">
                            <div className="modal-block__footer d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="total-service">Заказ на <span>{servicesForFooter.length}</span> услуг</div>
                                    <p className="text11pxnormalblue d-block total-service-name">
                                        {servicesForFooter.map((item,index)=>  item.name + " ")}
                                    </p>

                                </div>
                                {/*<div className="d-flex">*/}
                                {/*    <a href="#" onClick={()=>{sendApplicationSelectedServices()}} className="btn btn-success">Сделать заказ</a>*/}
                                {/*</div>*/}
                            </div>

                        </div>
                    </div>
                </>
            )
    }

}

export default ApplicationFooterService;