import React, {useEffect, useState} from 'react';
import {applicationsSingle} from "../../../../../store/applications/applications-single/applicationsSingleSlice";
import {useDispatch} from "react-redux";
import {currentApplicationServiceById} from "../../../../../store/applications/application-service-order/applicationServiceOrderSlice";
import {getMessagesFromChat} from "../../../../../store/chat/chatSlice";

function HeaderNotificationMessageItem(
    {
        item,
        openModal,
        setActiveTabKey,
        setNewNotification
    }) {
    const dispatch = useDispatch()
    const handleClick = (applicationId, type, orderID, e) => {
        e.preventDefault()
        switch (type){
            case 'applicationChat' :
                setActiveTabKey(
                    {
                        tabName : 'chat',
                        serviceId : applicationId,
                        applicationId : orderID
                    })
                openModal(applicationId,type='chat')
                setNewNotification((prevNotifications) => {
                    if (prevNotifications !== 0) {
                        return prevNotifications - 1;
                    } else {
                        return 0;
                    }
                });
                break
        }
    }

     return (

         item && item.type === 'applicationChat' ?
            <li
                key={item.id}
                className={item.type}
                onClick={(e)=>{handleClick(item.id,item.type, item.order_id, e)}}>
                <a href={'#'} className="all-notif">
                    <b>#{item.id}:</b> {item.short}
                </a>
            </li> : ''

    )
}

export default HeaderNotificationMessageItem;